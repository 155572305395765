import * as React from 'react';
import { useDispatch } from 'react-redux';
import { open, switchMode } from 'src/explore/compositions/Auth/actions';

import styles from './index.module.sass';

interface IAuthRequiredMessageProps {
  isGiftCardsEnabled?: boolean;
}

const AuthRequiredMessage: React.FC<IAuthRequiredMessageProps> = ({
  isGiftCardsEnabled = false,
}) => {
  const dispatch = useDispatch();

  const handleClick = ( action: 'login' | 'signup' ) => {
    dispatch( switchMode( action ));
    dispatch( open());
  };

  return (
    <div className="mt-4 mb-2 spec__auth-required-message">
      <a className={styles.link} onClick={() => handleClick( 'login' )}>
        Log in
      </a>
      {` `}or{` `}
      <a className={styles.link} onClick={() => handleClick( 'signup' )}>
        create an account
      </a>
      {` `}to use a promo code{isGiftCardsEnabled && ' or gift cards'}.
    </div>
  );
};

export default AuthRequiredMessage;
