/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';

import { KlarnaElement } from 'src/shoppe/elements/KlarnaElement';
import { IStore } from 'src/explore/types/store';

const MAX_QUALIFICATION = 100000;
const MIN_QUALIFICATION = 1000;

export const KlarnaMessaging = ({ isPreCartMode }: { isPreCartMode: boolean }) => {
  const cart = useSelector(( state: IStore ) => state.data.cart.data );
  const containsGiftCard = useSelector(( state: IStore ) => state.data.cart.containsGiftCard );
  const containsMonthlySubscription = useSelector(
    ( state: IStore ) => state.data.cart.containsMonthlySubscription
  );
  const total = isPreCartMode
    ? cart.order_total_after_discounts_and_gift_card_before_shipping_in_cents
    : cart.total_in_cents;

  const isQualifiedPurchase = total >= MIN_QUALIFICATION && total <= MAX_QUALIFICATION;

  return (
    <div className="fs-3 pt-md-4 px-md-6 spec__klarna-osm">
      {containsGiftCard ? (
        <div className="text-right text-md-center spec__gift-card-error">
          Gift Cards do not qualify for Klarna payments.
        </div>
      ) : !isQualifiedPurchase ? (
        <div className="text-center spec_qualify-error">
          Only orders between $10 and $1000 qualify for Klarna payments.
        </div>
      ) : containsMonthlySubscription ? (
        <div className="text-right text-md-center spec__subscription-error">
          Klarna cannot be used to pay for subscriptions that are billed monthly.
        </div>
      ) : (
        <KlarnaElement type="credit-promotion-auto-size" purchaseAmountInCents={total} />
      )}
    </div>
  );
};
