import React from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';

import ToSentence from 'src/explore/elements/ToSentence';
import * as cartItemsActionCreators from 'src/explore/data/cart/cart_items/actions';
import CartItem from './elements/CartItem';

export const Subcart = ({
  className = {},
  editable,
  egifting,
  hideEditLink,
  hideShopName,
  removeCartItem,
  subcart,
  updateCartItem,
  verbose,
}) => {
  // TODO: in some cases backend returns suborders with 0 items
  if ( subcart.cart_items.length === 0 ) {
    return null;
  }

  const merchantNameLinks = subcart.merchants.map(( merchant ) =>
    hideEditLink ? (
      <span key={merchant.id}>{merchant.name}</span>
    ) : (
      <a href={merchant.path} key={merchant.id} data-turbolinks="false">
        {merchant.name}
      </a>
    )
  );

  return (
    <div className="v2-cart-shop">
      {!hideShopName && (
        <div className={cls( 'font-weight-bold text-truncate spec__shop-name', className.merchant )}>
          {verbose && 'Order from '}
          <ToSentence>{merchantNameLinks}</ToSentence>
        </div>
      )}
      {subcart.cart_items.map(( item ) => (
        <CartItem
          className={className}
          item={item}
          key={item.id}
          editable={editable}
          egifting={egifting}
          hideEditLink={hideEditLink}
          onUpdateQuantity={( quantity ) =>
            updateCartItem( item.id, { cart_item: { quantity }}, egifting )
          }
          onRemove={() => removeCartItem( item.id, egifting )}
        />
      ))}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateCartItem: ( id, params, egifting = false ) =>
    cartItemsActionCreators.update( id, params, egifting ),
  removeCartItem: ( id, egifting = false ) => cartItemsActionCreators.destroy( id, egifting ),
};

export default connect( mapStateToProps, mapDispatchToProps )( Subcart );
