import React, { ReactNode } from 'react';

interface ImageWrapProps {
  children?: ReactNode;
  href?: string;
}

export const ImageWrap = ({ children, href }: ImageWrapProps ) =>
  href ? (
    <a className="v2-cart-item__edit-link spec__edit-link" data-turbolinks="false" href={href}>
      {children}
      <div className="text-center">edit</div>
    </a>
  ) : (
    <span>{children}</span>
  );
