/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import cls from 'classnames';

interface Props extends React.HTMLProps<HTMLLabelElement> {
  className?: string;
}

export const Label = React.forwardRef<HTMLLabelElement, Props>(
  ({ className, ...attributes }, ref ) => (
    <label ref={ref} className={cls( className, 'd-block m-0 p-0' )} {...attributes} />
  ),
);
