// Overloading ahoy track so that it populates the URL for every call

let configured = false;

export default ( name, properties = {}) => {
  if ( configured === false && window.ahoy ) {
    window.ahoy.configure({
      visitDuration: 30
    });
    configured = true;
  }
  if ( window.ahoy ) {
    const propertiesForEvent = Object.assign( properties, { url: window.location.href });
    window.ahoy.track( name, propertiesForEvent );
  }
};
