const shorten = ( number, options = {}) => {
  if ( options.min && number < options.min ) {
    return number.toString();
  }

  if ( number >= 1000000 ) {
    const integer = Math.trunc( number / 1000000 );
    const digits = Math.trunc( number / 100000 ) % 10;

    return `${integer}.${digits}M`;
  }

  if ( number >= 1000 ) {
    const integer = Math.trunc( number / 1000 );
    const digits = Math.trunc( number / 100 ) % 10;

    return `${integer}.${digits}k`;
  }

  return number?.toString();
};

export default {
  shorten,
};
