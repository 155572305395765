import React, { useMemo } from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';

import CartLineItem from 'src/explore/elements/CartLineItem';
import { Total } from './elements/Total';

export const CartBill = ({ cart, className = {}, mode = '' }) => {
  const isPreCartMode = mode === 'precart';
  const isForPickUp = cart.is_for_pick_up;
  const isForLocalDelivery = cart.is_for_local_delivery;
  const isForInternational =  !!cart.aggregated_totals?.import_shipping_surcharge_subtotal_in_cents;
  const taxCalculationsComplete = cart.tax_calculations_complete;
  const isFinalScreen =
    window.location.pathname === '/buy/confirmation/new' ||
    window.location.pathname.includes( 'purchase' );
  const giftCardsSubtotalInCents = useMemo(
    () =>
      cart.gift_cards?.reduce(( total, giftCard ) => total + ( giftCard.value_in_cents || 0 ), 0 ) ?? 0,
    [ cart.gift_cards ]
  );
  const totalToDisplay = isFinalScreen
    ? cart.total_in_cents
    : cart.total_in_cents - ( cart.taxes_total_in_cents ?? 0 );

  return (
    <div className={cls( className.container )}>
      <CartLineItem
        name="Subtotal"
        className={{ container: className.lineItem, spec: 'spec__line-item-subtotal' }}
        undiscountedValue={
          cart.show_undiscounted_subtotal &&
          ( cart.undiscounted_cart_items_subtotal_in_cents ?? 0 ) + giftCardsSubtotalInCents
        }
        value={( cart.cart_items_subtotal_in_cents ?? 0 ) + giftCardsSubtotalInCents}
      />

      {cart.promotion && (
        <CartLineItem
          name={cart.promotion.description}
          className={{ container: className.lineItem, spec: 'spec__line-item-promotion' }}
          value={-cart.promotion_discount_in_cents}
        />
      )}

      {!isPreCartMode && cart.discount_codes?.promo_code?.value && (
        <CartLineItem
          className={{ container: cls( className.lineItem, 'd-lg-none' ) }}
          name="Promo Code"
          value={-cart.discount_codes.promo_code.value}
        />
      )}

      {cart.applied_account_credits_in_cents > 0 && (
        <CartLineItem
          name="Account Credits"
          className={{ container: className.lineItem, spec: 'spec__line-item-credits' }}
          value={-cart.applied_account_credits_in_cents}
        />
      )}

      {!isPreCartMode && (
        <>
          {cart.discount_codes?.applied_gift_cards?.map(( giftCard ) =>
            giftCard.applied_amount_in_cents ? (
              <CartLineItem
                className={{ container: cls( className.lineItem, 'd-lg-none' ) }}
                key={giftCard.code}
                name="Gift Card"
                value={-giftCard.applied_amount_in_cents}
              />
            ) : null
          )}

          {!isForPickUp && (
            <CartLineItem
              className={{ container: className.lineItem, spec: 'spec__line-item-shipping' }}
              name="Shipping"
              value={
                cart.shipping_subtotal_in_cents === 0 ? 'Free' : cart.shipping_subtotal_in_cents
              }
            />
          )}

          {isForLocalDelivery && (
            <CartLineItem
              className={{ container: className.lineItem, spec: 'spec__line-item-local-delivery' }}
              name="Local delivery fee"
              value={
                cart.local_delivery_fee_in_cents === 0 ? 'Free' : cart.local_delivery_fee_in_cents
              }
            />
          )}

          {isForPickUp && !isForLocalDelivery && (
            <CartLineItem
              className={{ container: className.lineItem, spec: 'spec__pick-up' }}
              name="Local pick-up fee"
              value={0}
            />
          )}

          {taxCalculationsComplete && isFinalScreen && (
            <CartLineItem
              className={{ container: className.lineItem, spec: 'spec__line-item-estimated-tax' }}
              name="Estimated Tax"
              value={cart.taxes_total_in_cents}
            />
          )}
          {/* TODO: replace with real values */}
          {isForInternational && isFinalScreen && (
            <CartLineItem
              className={{ container: className.lineItem, spec: 'spec__line-item-surcharge' }}
              name="Canada Surcharge"
              showTooltip
              value={cart.aggregated_totals.import_shipping_surcharge_subtotal_in_cents}
            />
          )}
          <Total
            className={{
              cents: className.totalCents,
              container: cls( className.total, className.lineItem ),
            }}
            isForInternational={isForInternational}
            isForPickUp={isForPickUp}
            shippingSubtotal={cart.shipping_subtotal_in_cents}
            totalToDisplay={totalToDisplay}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ( state ) => ({
  cart: state.data.cart.data,
});

const mapDispatchToProps = {};

export default connect( mapStateToProps, mapDispatchToProps )( CartBill );
