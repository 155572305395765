import React from 'react';
import { useSelector } from 'react-redux';

import CartLineItem from 'src/explore/elements/CartLineItem';
import styles from './index.module.sass';

export const MobileCheckout = React.forwardRef(({ account, isForPickUp, onCheckout }, ref ) => {
  const cart = useSelector(( state ) => state.data.cart.data );

  let guestCheckOut = false;
  if ( !account && !isForPickUp ) {
    guestCheckOut = true;
  }

  return (
    <div
      className={`${styles.container} bg-light d-md-none fs-4 mt-4 pt-3 pb-4 position-absolute w-100`}
    >
      <div className="px-6">
        <CartLineItem
          className="text-right"
          name="Subtotal"
          nameClass="float-left font-weight-bold"
          undiscountedValue={
            cart.show_undiscounted_subtotal && cart.undiscounted_cart_items_subtotal_in_cents
          }
          value={cart.order_total_after_discounts_and_gift_card_before_shipping_in_cents}
        />

        <div className={`${styles.checkoutContainer} position-relative mt-n1 mx-n6`} ref={ref}>
          <div className="bg-light px-6 py-2">
            <div className="w-100">
              <a
                className="w-100 btn btn-secondary spec__cart__goto-checkout-mobile-btn"
                onClick={() => onCheckout( guestCheckOut, isForPickUp )}
              >
                Check Out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
