import React from 'react';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';

import styles from './index.module.sass';

interface PriceProps {
  discounted: number;
  displayDiscount: boolean;
  undiscounted: number;
}

export const Price = ({ discounted, displayDiscount, undiscounted }: PriceProps ) => (
  <div>
    {displayDiscount && undiscounted > discounted && (
      <del className={`${styles.muted} fs-4 spec__cart-item__undiscounted-price`}>
        <DollarsAndCents
          amountInCents={undiscounted}
          className={{ cents: `${styles.super} position-relative fs-3` }}
        />
      </del>
    )}
    <span className="ml-3 spec__cart-item__price">
      <DollarsAndCents
        amountInCents={discounted}
        className={{ cents: `${styles.super} position-relative fs-3` }}
      />
    </span>
  </div>
);
