import React from 'react';
import cls from 'classnames';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';

import styles from './index.module.sass';

interface TotalProps {
  className?: {
    cents?: string;
    container?: string;
    item?: string;
  };
  isForInternational: boolean;
  isForPickUp: boolean;
  shippingSubtotal?: number;
  totalToDisplay: number;
}

export const Total = ({ className, isForInternational, isForPickUp, shippingSubtotal, totalToDisplay }: TotalProps ) => (
  <div className={cls( 'py-1 font-weight-bold spec__line-item-total', className.container )}>
    <span className="d-inline-block pr-3">Total</span>

    <span className="d-inline-block pl-4 spec__order-total">
      {
        /* When doing shipping, we don't show total unless shipping is set */
        !isForPickUp && shippingSubtotal == null ? (
          '--'
        ) : (
          <>
            {isForInternational && "USD "}
            <DollarsAndCents
              amountInCents={totalToDisplay}
              className={{ cents: cls( styles.super, 'position-relative fs-3', className.cents ) }}
            />
          </>
        )
      }
    </span>
  </div>
);
