import React from 'react';

import CartBill from 'src/explore/compositions/CartBill';

import styles from './index.module.sass';

const Footer = ({ mode }) => (
  <footer className="v2-cart__footer">
    <CartBill
      className={{ container: 'my-3 text-right', total: 'fs-6', totalCents: styles.cents }}
      mode={mode}
    />
  </footer>
);

export default Footer;
