import React from 'react';
import cls from 'classnames';

import ButtonWithIcon from 'src/explore/elements/bs4/ButtonWithIcon';

import { ntc } from 'src/explore/services/currency_helper';

import styles from './index.module.sass';

export const PurchaseRewards = ({ tiers, onClose }) =>
  !tiers ? null : (
    <div
      className={cls(
        'pt-5 pb-8 px-6 text-center text-white spec__purchase-rewards',
        styles.component
      )}
    >
      <div className="d-flex flex-row no-gutters justify-content-end">
        <div className="col-4">
          <ButtonWithIcon
            className="btn btn-sm btn-outline-light btn-block"
            svgClasses="rotate"
            icon="icon-plus"
            onClick={onClose}
          >
            Close
          </ButtonWithIcon>
        </div>
      </div>

      <div className="mx-8">
        <div className="row mt-4">
          <div className={cls( 'col py-3 font-weight-bold', styles.title )}>
            ONLY &apos;TIL FRIDAY!
          </div>
        </div>

        <div className="row align-items-center mt-6 pb-1 fs-8 font-weight-bold text-left">
          <div className="col-auto p-0">
            <div className={styles.box}>SPEND</div>

            {tiers.map(( tier ) => (
              <div className={styles.box} key={tier.id}>
                {`${ntc( tier.min_total_in_cents, { verbose: false })}+`}
              </div>
            ))}
          </div>

          <div className="col px-9">
            <div className={styles.box} />

            {tiers.map(( tier ) => (
              <div className={cls( 'd-flex', styles.box )} key={tier.id}>
                <div className={styles.arrowContainer}>
                  <div className={styles.arrow} />
                </div>
              </div>
            ))}
          </div>

          <div className="col-auto p-0">
            <div className={styles.box}>GET</div>

            {tiers.map(( tier ) => (
              <div className={styles.box} key={tier.id}>
                {ntc( tier.discount_in_cents, { verbose: false })}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-5 mb-9 fs-3">
          Order by 10/29 and schedule delivery by 11/5.{' '}
          <a
            href="https://help.goldbelly.com/hc/en-us/articles/360040840734-Spend-More-Get-More-Promotion"
            className={styles.readMore}
            rel="noopener noreferrer"
            target="_blank"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
