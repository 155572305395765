import React from 'react';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';
import SvgIcon from 'src/explore/elements/SvgIcon';

import styles from './index.module.sass';

const DiscountCode = ({ appliedAmountInCents, code, description, onDelete }) => (
  <div className="v2-cart__extra-content v2-cart__code-line-item spec__promo-code">
    <div className="v2-cart-code-line-item__code">{code}</div>

    {description && <div className="v2-cart-code-line-item__detail">{`(${description})`}</div>}

    <div className="v2-cart-code-line-item__value">
      {!!appliedAmountInCents && (
        <DollarsAndCents
          amountInCents={-appliedAmountInCents}
          className={{ cents: `${styles.super} position-relative p-0` }}
        />
      )}
    </div>

    <div className="v2-cart__extra-action spec__promo-code-delete" onClick={onDelete}>
      <SvgIcon icon="icon-close-circle" />
    </div>
  </div>
);

export default DiscountCode;
