import React from 'react';
import cls from 'classnames';

import SvgIcon from 'src/explore/elements/SvgIcon';

interface Props {
  children: React.ReactNode;
  className?: string;
  icon: string;
  svgClasses?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

const ButtonWithIcon: React.FC<Props> = ({
  children,
  className = '',
  icon,
  svgClasses,
  type = 'button',
  ...attrs
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type} // because it does have a type, eslint you goof
    className={cls(
      'd-flex flex-row no-gutters flex-nowrap',
      'justify-content-between align-items-center',
      className
    )}
    {...attrs}
  >
    <span className="col mr-1">{children}</span>
    <SvgIcon svgClasses={`d-flex ${svgClasses}`} icon={icon} />
  </button>
);

export default ButtonWithIcon;
