import * as React from 'react';
import cls from 'classnames';

interface Props {
  className?: string;
  purchaseAmountInCents?: number;
  type: JSX.IntrinsicElements['klarna-placement']['data-key'];
}

export const KlarnaElement = ({ className, purchaseAmountInCents = 0, type }: Props ) => {
  React.useEffect(() => {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
  }, [ type, purchaseAmountInCents ]);

  return (
    <div data-turbolinks="false" className={cls( className, 'spec__klarna-element' )}>
      <klarna-placement
        data-key={type}
        data-locale="en-US"
        data-purchase-amount={`${purchaseAmountInCents}`}
      />
    </div>
  );
};
