import React from 'react';
import { connect } from 'react-redux';

import Subcart from './elements/Subcart';

const Subcarts = ({
  className,
  editable,
  egifting,
  hideEditLink,
  hideShopName,
  subcarts,
  verbose,
}) =>
  subcarts.map(( subcart ) => (
    <Subcart
      className={className}
      subcart={subcart}
      key={subcart.id}
      editable={editable}
      egifting={egifting}
      hideEditLink={hideEditLink}
      hideShopName={hideShopName}
      verbose={verbose}
    />
  ));

const mapStateToProps = ( state ) => ({
  subcarts: state.data.cart.data.subcarts,
});

const mapDispatchToProps = {};

export default connect( mapStateToProps, mapDispatchToProps )( Subcarts );
