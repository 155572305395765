/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsdCircle } from '@fortawesome/pro-solid-svg-icons';

import { IRewardPromo } from 'src/explore/types/shoppe';
import { IStore } from 'src/explore/types/store';

import styles from './index.module.sass';

interface Props {
  className?: string;
  onRewardsClick: () => void;
}

export const RewardsInfo = ({ className, onRewardsClick }: Props ) => {
  const account = useSelector(( state: IStore ) => state.data.account.data );
  const promos = useSelector(( state: IStore ) => state.data.rewardPromos.data );

  const maxAvailableReward = React.useMemo(() => {
    let max: IRewardPromo;
    if ( promos && account?.reward_points ) {
      promos.forEach(( promo ) => {
        if (
          promo.price_in_points < account.reward_points &&
          ( promo.discount_in_cents > max?.discount_in_cents || !max )
        ) {
          max = promo;
        }
      });
    }
    return max;
  }, [ account?.reward_points, promos ]);

  return (
    <div className={cls( className, 'spec__rewards-info' )}>
      <div className="d-flex align-items-center justify-content-center">
        <FontAwesomeIcon className="fs-10 text-secondary" icon={faUsdCircle} />

        <div className="col fs-4 fs-md-5 px-2 text-center">
          <div>
            You have <strong>{account?.reward_points}</strong> points!{' '}
            {maxAvailableReward && (
              <span>
                (worth
                <strong className="text-secondary">
                  {` $${maxAvailableReward.discount_in_cents / 100}`}
                </strong>
                )
              </span>
            )}
          </div>

          <button
            className={cls(
              styles.link,
              'bg-transparent border-0 fs-4 fs-md-5 m-auto p-0 text-secondary'
            )}
            type="button"
            onClick={onRewardsClick}
          >
            Redeem Your Rewards Points Now
          </button>
        </div>
      </div>
    </div>
  );
};
