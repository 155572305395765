import api from 'src/explore/services/api';

// Actions

export const DESTROY = 'cart/gift_cards/DESTROY';
export const DESTROY_SUCCESS = 'cart/gift_cards/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'cart/gift_cards/DESTROY_FAILURE';

// Action creators

function destroySuccess() {
  return { type: DESTROY_SUCCESS };
}

function destroyFailure( error ) {
  return { type: DESTROY_FAILURE, payload: error };
}
export function destroy( giftCardId ) {
  return ( dispatch ) => {
    dispatch({ type: DESTROY });

    const request = api.delete( `gift_cards/${giftCardId}` );

    return request
      .then(() => dispatch( destroySuccess()))
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( destroyFailure( error.response.data ));
        }
      });
  };
}
