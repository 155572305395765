import React from 'react';
import SvgIcon from 'src/explore/elements/SvgIcon';

const PaymentMethods = () => (
  <div className="v2-cart__payment-methods text-center">
    <div className="v2-payment-methods__title">Accepted payment methods</div>
    <div className="v2-payment-methods__body">
      <div className="v2-payment-methods__group">
        <SvgIcon icon="icon-mastercard" baseline />
        <SvgIcon icon="icon-visa" baseline />
        <SvgIcon icon="icon-amex" baseline />
        <SvgIcon icon="icon-discover" baseline />
        <SvgIcon icon="icon-paypal-pay" svgClasses="hide-in-app" baseline />
        <SvgIcon icon="icon-android-pay" svgClasses="hide-in-app" baseline />
        <SvgIcon icon="icon-apple-pay" baseline />
        <SvgIcon icon="icon-klarna-pay" baseline />
      </div>
    </div>
  </div>
);

export default PaymentMethods;
