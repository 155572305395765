import api from 'src/explore/services/api';

import { fetch as fetchCart } from '../actions';

// Actions

export const DESTROY = 'cart/gift_card_codes/DESTROY';
export const DESTROY_SUCCESS = 'cart/gift_card_codes/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'cart/gift_card_codes/DESTROY_FAILURE';

// Action creators

export function destroySuccess() {
  return { type: DESTROY_SUCCESS };
}

export function destroyFailure( error ) {
  return { type: DESTROY_FAILURE, payload: error };
}
export function destroy( code, egifting = false  ) {
  return ( dispatch ) => {
    dispatch({ type: DESTROY });

    const request = api.delete( `gift_card_codes/${code}` );

    return request
      .then(() => {
        dispatch( destroySuccess());
        dispatch( fetchCart( egifting ));
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( destroyFailure( error.response.data ));
        }
      });
  };
}
