import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

let Form = ({ className = '', handleSubmit, pristine, submitting }) => (
  <form className={className} onSubmit={handleSubmit}>
    <div className="form-group">
      <Field name="comment" component="textarea" className="form-control p-4 mr-2 bg-white" />
      <button
        type="submit"
        className="btn btn-secondary py-0 px-8 font-weight-bold fs-6"
        disabled={submitting}
      >
        Add
      </button>
    </div>
  </form>
);

Form = reduxForm({
  form: 'comment', // unique identifier
})( Form );

const mapStateToProps = ( state ) => ({
  initialValues: {
    comment: state.data.cart.data.comment ? state.data.cart.data.comment.body : '',
  },
});

export default connect( mapStateToProps )( Form );
