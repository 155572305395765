import React from 'react';

import Comment from '../Comment';
import DiscountCodes from '../DiscountCodes';

const Extras = ({ className = '', egifting = false, showComment }) => (
  <aside className={`${className} v2-cart__extras`}>
    {showComment && (
      <div className="mb-4">
        <Comment />
      </div>
    )}
    <DiscountCodes egifting={egifting} />
  </aside>
);

export default Extras;
