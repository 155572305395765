import React from 'react';
import cls from 'classnames';

import SvgIcon from 'src/explore/elements/SvgIcon';

import styles from './index.module.sass';

interface ProductWarningProps {
  children?: React.ReactNode;
  className?: {
    container?: string;
    icon?: string;
  };
}

export const ProductWarning = ({ children, className }: ProductWarningProps ) => (
  <div className={cls( 'd-flex border border-light', className.container )}>
    <div className={cls( 'd-flex align-items-center p-3 text-secondary fs-7', className.icon )}>
      <SvgIcon icon="icon-warn" />
    </div>
    <div className={cls( 'd-flex flex-fill align-items-center py-4 px-3 fs-4', styles.children )}>
      {children}
    </div>
  </div>
);
