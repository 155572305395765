import React from 'react';
import cls from 'classnames';
import { Field, reduxForm } from 'redux-form';

import { Label } from 'src/explore/elements/Label';
import styles from './index.module.sass';

let DiscountCodeForm = ({
  className = '',
  navyButtons = false,
  handleSubmit,
  pristine,
  submitting,
  onChange,
}) => (
  <form className={`${className} spec__promo-code-form`} onSubmit={handleSubmit}>
    <div className="form-group pb-0 mb-0">
      <Label htmlFor="code" className="w-100" aria-label="Discount Code">
        <Field
          id="code"
          name="code"
          component="input"
          className={`${styles.input} form-control px-4 mr-2 bg-white`}
          onChange={onChange}
        />
      </Label>
      <button
        aria-label="Add Code"
        type="submit"
        className={cls(
          navyButtons ? styles.buttonDark : 'btn-outline-secondary',
          'btn py-0 px-8 ml-2 font-weight-bold fs-6'
        )}
        disabled={pristine || submitting}
      >
        Add
      </button>
    </div>
  </form>
);

export default DiscountCodeForm = reduxForm({
  form: 'discount_code', // unique identifier
})( DiscountCodeForm );
