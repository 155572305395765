import React from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';

import SvgIcon from 'src/explore/elements/SvgIcon';
import * as dataActionCreators from 'src/explore/data/cart/comment/actions';

import Form from './elements/Form';
import styles from './index.module.sass';
import * as viewActionCreators from './actions';

export const Comment = ({ comment, open, toggle, addComment }) => {
  const isOpen = !!open;

  return (
    <div
      className={`v2-cart__extra-container spec__comment ${isOpen ? 'open' : ''}`}
      aria-expanded={isOpen}
    >
      <div
        className={`${styles.toggle} d-flex spec__comment-header`}
        onClick={toggle}
        role="button"
        aria-pressed={isOpen}
      >
        <div className="v2-cart__extra-title font-weight-bold">
          <SvgIcon icon="icon-pencil" baseline />
          {comment ? ' Edit Instructions' : ' Special Instructions'}
        </div>
      </div>
      <div
        className={cls( `${styles.collapse} v2-cart__extra-content overflow-hidden`, {
          [styles.expand]: open,
        })}
      >
        <Form className="mt-3" onSubmit={addComment} />
      </div>
      {!isOpen && (
        <div className="v2-cart__extra-content v2-cart__extra-content--content spec__comment-preview">
          {comment ? comment.body : 'E.g. allergy or dietary info'}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ( state ) => ({
  comment: state.data.cart.data.comment,
  open: state.Cart.Comment.open,
});

const mapDispatchToProps = {
  addComment: ( params ) => dataActionCreators.create( params ),
  toggle: () => viewActionCreators.toggle(),
};

export default connect( mapStateToProps, mapDispatchToProps )( Comment );
