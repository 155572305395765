import React from 'react';

const requiresHtml = ( message ) => message == 'No products in your cart qualify for this discount.';
const containsHelpUrl = ( helpUrl ) => typeof helpUrl !== 'undefined' && helpUrl !== null;

const getFormatedErrorMessage = ( message, helpUrl ) => {
  const _url = containsHelpUrl( helpUrl )
    ? helpUrl
    : 'https://help.goldbelly.com/hc/en-us/articles/115003609894';
  return (
    <div>
      <span>{message}</span>{' '}
      <a href={_url} target="_blank" rel="noopener noreferrer">
        See Rules
      </a>
    </div>
  );
};

const Error = ({ message, helpUrl }) => (
  <div className="v2-cart__extra-errors text-danger">
    {containsHelpUrl( helpUrl ) || requiresHtml( message )
      ? getFormatedErrorMessage( message, helpUrl )
      : message
    }
  </div>
);

export default Error;
