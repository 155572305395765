import React from 'react';
import type { FC, ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';

import styles from './index.module.sass';

type Props = {
  children?: ReactNode;
  learnMore?: string;
}

export const TooltipV2: FC<Props> = ({ children, learnMore }) => (
  <div className={styles.tooltip}>
    <div className={styles.tooltipIcon}>
      <FontAwesomeIcon icon={faCircleInfo} />
      <div className={styles.tooltipWrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 17">
          <path d="M8 16.5V0.5L0 8.50061L8 16.5Z" />
        </svg>
        <div className={styles.tooltipText}>
          {children}{ ' ' }
          {learnMore && <a href={learnMore} target="_blank" rel="noreferrer">Learn More</a>}
        </div>
      </div>
    </div>
  </div>
);
