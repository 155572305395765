import { getInstance } from '@eppo/js-client-sdk';

type IAssignment = 'string' | 'boolean' | 'number' | 'integer' | 'JSON';

const getAssignment = (
  experiment: string,
  assignmentType: IAssignment,
  attributes: { [key: string]: string | boolean | number } = {}
) => {
  if ( window.eppo?.experimentOverrides[experiment]) {
    return window.eppo.experimentOverrides[experiment];
  }

  const subject = String( attributes.id || window.ahoy.getVisitorToken());
  if ( !subject ) return null;

  switch ( assignmentType ) {
    case 'boolean':
      return getInstance().getBoolAssignment( experiment, subject, attributes, null );
    case 'number':
      return getInstance().getNumericAssignment( experiment, subject, attributes, null );
    case 'integer':
      return getInstance().getIntegerAssignment( experiment, subject, attributes, null );
    case 'JSON':
      return getInstance().getJSONAssignment( experiment, subject, attributes, null );
    default:
      return getInstance().getStringAssignment( experiment, subject, attributes, null );
  }
};

interface IIsExperimentGroup {
  assignmentType?: IAssignment;
  attributes?: { [key: string]: string | boolean | number };
  experiment: string;
  group: string | boolean | number | JSON;
}

const isExperimentGroup = ({
  assignmentType,
  attributes = {},
  experiment,
  group,
}: IIsExperimentGroup ) =>
  getAssignment( experiment, assignmentType || ( typeof group as IAssignment ), attributes ) === group;

export default {
  getAssignment,
  isExperimentGroup,
};
