import React from 'react';

const Checkout = ({ account, isForPickUp, onCheckout }) => (
  <>
    <div className="v2-cart__actions spec_cart__actions d-none d-md-block text-center">
      <a
        href="javascript:"
        className="v2-form__btn v2-form__btn--primary v2-form__btn--block v2-cart__action--checkout spec__cart__goto-checkout-btn"
        onClick={() => onCheckout( !!( !account && !isForPickUp ), isForPickUp )}
      >
        Check Out
      </a>
    </div>
  </>
);

export default Checkout;
