import React from 'react';
import cls from 'classnames';

import { LazyImgix } from 'src/explore/elements/LazyImgix';
import SvgIcon from 'src/explore/elements/SvgIcon';

import { ImageWrap } from './elements/ImageWrap';
import { Price } from './elements/Price';

import styles from './index.module.sass';

const CartItem = ({
  className = {},
  item,
  hideEditLink,
  editable,
  egifting,
  onRemove,
  onUpdateQuantity,
}) => {
  const isAlmostSoldOut = !!item.product.almost_sold_out;

  return (
    <>
      <div className="v2-cart-item js-cart-item spec__cart-item">
        <div className={cls( 'v2-cart-item__image mr-2', className.image )}>
          <ImageWrap href={!hideEditLink ? `/cart_items/${item.id}` : null}>
            <LazyImgix
              src={item.product.effective_main_image_url}
              alt={item.product.effective_name}
              className="img-responsive"
              imgixParams={{ ar: '1:1' }}
              sizes="80px"
            />
          </ImageWrap>
        </div>

        <div className="flex-grow-1 mw-100 overflow-hidden">
          <div className="v2-cart-item__product-name-container">
            <div className="fs-4 spec__cart-item__name">
              {!hideEditLink ? (
                <a
                  className={cls( className.product )}
                  href={`/cart_items/${item.id}`}
                  data-turbolinks="false"
                >
                  {item.product.effective_name}
                </a>
              ) : (
                <span className={cls( className.product )}>{item.product.effective_name}</span>
              )}
            </div>
            {!!editable && (
              <button
                aria-label="Remove Item from Cart"
                className="v2-cart-item__remove p-0 border-0 bg-transparent js-cart-item__remove spec__remove-cart-item"
                role="link"
                tabIndex="0"
                type="button"
                onClick={onRemove}
              >
                <SvgIcon icon="icon-close-circle" />
              </button>
            )}
          </div>

          {item.customer_choices_summary.length > 0 && (
            <div className="v2-cart-item__detail">
              {item.customer_choices_summary.map(( summary ) => (
                <div className="v2-cart-item__option" key={summary}>
                  {summary}
                </div>
              ))}
            </div>
          )}

          <div className="v2-cart-item__quantity-price-container">
            <div className="v2-cart-item__quantity">
              {editable ? (
                <div>
                  <label className="v2-cart-item__quantity-label" htmlFor="cart_item[quantity]">
                    Qty
                  </label>
                  <select
                    name="cart_item[quantity]"
                    value={item.quantity}
                    onChange={( event ) => onUpdateQuantity( event.target.value )}
                    className="v2-cart-item__quantity-select js-cart-item__quantity"
                  >
                    {item.possible_quantities.map(( quantity ) => (
                      <option key={quantity.value} value={quantity.value}>
                        {quantity.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>
                  Qty <span className="v2-cart-item__quantity-value">{item.quantity}</span>
                </div>
              )}
            </div>
            <Price
              discounted={item.per_unit_discounted_price_in_cents}
              displayDiscount={item.product.display_discount_pricing}
              undiscounted={item.per_unit_undiscounted_price_in_cents}
            />
          </div>
        </div>
      </div>
      <div className={styles.alerts}>
        {isAlmostSoldOut && (
          <div className={cls( 'spec__cart-almost-sold-out', styles.almost )}>Almost Sold Out</div>
        )}

        {item.product.no_promotions_allowed && (
          <div className={cls( 'spec__cart-discount-warning', styles.warning )}>
            Not eligible for discount
          </div>
        )}

        {egifting && !item.product.is_egiftable && (
          <div className={styles.warning}>Cannot be sent as an E-Gift</div>
        )}
      </div>
    </>
  );
};

export default CartItem;
