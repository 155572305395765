import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import moment from 'moment';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';
import { ProductWarning } from 'src/shoppe/elements/ProductWarning';
import SvgIcon from 'src/explore/elements/SvgIcon';

import styles from './index.module.sass';

const GiftCard = ({ card, className = {}, editable, egifting = false, onRemove }) => {
  const [ toSendEmailAt, setToSendEmailAt ] = useState( null );

  useEffect(() => {
    if ( card.to_send_email_at ) {
      const date = moment( card.to_send_email_at );
      const day = date.format( 'M/DD/YY' );
      const time = date.format( 'h:mm a' );
      setToSendEmailAt( `${day} at ${time}` );
    }
  }, [ card.to_send_email_at ]);

  return (
    <>
      <div className="v2-cart-item">
        <div className={cls( 'v2-cart-item__image mr-2', className.image )}>
          <img src={card.picture_url} alt={card.title} className="img-responsive" />
        </div>

        <div className="flex-grow-1 mw-100 overflow-hidden">
          <div className="v2-cart-item__product-name-container">
            <div className={cls( 'fs-4', className.product )}>{card.title}</div>

            {!!editable && (
              <div className="v2-cart-item__remove" role="link" tabIndex="0" onClick={onRemove}>
                <SvgIcon icon="icon-close-circle" />
              </div>
            )}
          </div>

          <div className="v2-cart-item__quantity-price-container">
            <div className="v2-cart-item__quantity">
              <div>
                Qty <span className="v2-cart-item__quantity-value">1</span>
              </div>
            </div>

            <div className="v2-cart-item__price">
              <span className="v2-cart-item__price-value spec__cart-item__price">
                <DollarsAndCents
                  amountInCents={card.value_in_cents}
                  className={{ cents: `${styles.cents} position-relative fs-3` }}
                />
              </span>
            </div>
          </div>

          <div className="v2-cart-item__detail">
            <strong>To: </strong>
            {`${card.to} (${card.recipient_email})`}
          </div>
          <div className="v2-cart-item__detail">
            <strong>From: </strong>
            {`${card.from} (${card.sender_email})`}
          </div>
          <div className="v2-cart-item__detail">
            <strong>Message: </strong>
            {card.message_to_recipient}
          </div>
          <div className="v2-cart-item__detail">
            <strong>Send on: </strong>
            {toSendEmailAt || 'Right Away'}
          </div>
        </div>
      </div>

      {egifting && (
        <ProductWarning
          className={{
            container: cls( 'w-100 mt-4', className.warning ),
            icon: className.warningIcon,
          }}
        >
          This product cannot be sent as an E-Gift
        </ProductWarning>
      )}
    </>
  );
};
export default GiftCard;
