import React from 'react';

const Empty = ({ onBackClicked }) => (
  <div className="v2-cart-empty">
    <div className="v2-cart-empty__text">Your cart is empty.</div>
    <span className="v2-cart-empty__button btn btn-secondary" onClick={onBackClicked}>
      Start Shopping
    </span>
  </div>
);

export default Empty;
