import api from 'src/explore/services/api';
import GA, { EVENT_ADD_TO_CART } from 'src/explore/services/ga';

import { fetch } from '../actions';

// Actions

export const CREATE = 'cart/cart_items/CREATE';
export const CREATE_SUCCESS = 'cart/cart_items/CREATE_SUCCESS';
export const CREATE_FAILURE = 'cart/cart_items/CREATE_FAILURE';

export const UPDATE = 'cart/cart_items/UPDATE';
export const UPDATE_SUCCESS = 'cart/cart_items/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'cart/cart_items/UPDATE_FAILURE';

export const DESTROY = 'cart/cart_items/DESTROY';
export const DESTROY_SUCCESS = 'cart/cart_items/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'cart/cart_items/DESTROY_FAILURE';

// Action creators

function createSuccess( item ) {
  return { type: CREATE_SUCCESS, payload: item };
}

function createFailure( error ) {
  return { type: CREATE_FAILURE, payload: error };
}
export function create( params ) {
  return ( dispatch ) => {
    dispatch({ type: CREATE });

    const request = api.post( 'cart_items', params );

    return request
      .then(( response ) => {
        const cartItem = response.data;

        dispatch( createSuccess( cartItem ));

        // TODO: not sure of a better way to do this
        dispatch( fetch());

        // Track analytics
        if ( typeof window.fbq !== 'undefined' ) {
          window.fbq( 'track', 'AddToCart', {
            content_ids: [ cartItem.product.id ],
            content_type: 'product',
            value: cartItem.product.display_price,
            currency: 'USD',
          });

          fire_tn_pixel( window, document, 'add_cart', `${cartItem.product.id}` );
        }

        window.analytics.track(
          'Product Added',
          {
            cart_id: cartItem.order_id.toString(),
            ...cartItem.data_for_analytics,
          },
          {
            integrations: {
              All: false,
              'Google Tag Manager': true,
              'TikTok Pixel': true,
              'Facebook Pixel': true,
              'Pinterest Tag': true,
              Repeater: true,
            },
          }
        );
        GA.trackEvent( EVENT_ADD_TO_CART, {
          product_id: cartItem.product.id,
        });
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( createFailure( error.response.data ));
        }
      });
  };
}

function updateSuccess() {
  return { type: UPDATE_SUCCESS };
}

function updateFailure( error ) {
  return { type: UPDATE_FAILURE, payload: error };
}
export function update( cartItemId, params, egifting = false ) {
  return ( dispatch ) => {
    dispatch({ type: UPDATE });

    const request = api.put( `cart_items/${cartItemId}`, params );

    return request
      .then(() => {
        dispatch( updateSuccess());

        if ( /^\/buy\/?/.test( window.location.pathname )) {
          if ( /^\/buy\/egifting\/?/.test( window.location.pathname )) {
            window.location.reload();
          } else {
            window.location.href = '/buy/shipping/edit';
          }
        } else {
          // TODO: not sure of a better way to do this
          dispatch( fetch( egifting ));
        }
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( updateFailure( error.response.data ));
        }
      });
  };
}

function destroySuccess() {
  return { type: DESTROY_SUCCESS };
}

function destroyFailure( error ) {
  return { type: DESTROY_FAILURE, payload: error };
}
export function destroy( cartItemId, egifting = false ) {
  return ( dispatch ) => {
    dispatch({ type: DESTROY });

    const request = api.delete( `cart_items/${cartItemId}` );

    return request
      .then(( response ) => {
        const cartItem = response.data;

        dispatch( destroySuccess());

        if ( /^\/buy\/?/.test( window.location.pathname )) {
          window.location.reload();
        } else {
          // TODO: not sure of a better way to do this
          dispatch( fetch( egifting ));
        }

        window.analytics.track( 'Product Removed', {
          cart_id: cartItem.order_id.toString(),
          ...cartItem.data_for_analytics,
        });
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( destroyFailure( error.response.data ));
        }
      });
  };
}
