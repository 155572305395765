import React from 'react';
import cls from 'classnames';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';
import { TooltipV2 } from 'src/explore/elements/TooltipV2';

import styles from './index.module.sass';

const CartLineItem = ({ className = {}, name, nameClass = '', showTooltip = false, undiscountedValue, value }) => (
  <div className={cls( 'py-1', className.container, className.spec )}>
    <span
      className={cls( nameClass, 'd-flex mr-4', {
        [`${className.spec}-name`]: className.spec,
      })}
    >
      {name}
      {showTooltip && (
        <TooltipV2>
          This fee covers additional costs incurred by Goldbelly to deliver products internationally.
        </TooltipV2>
      )}
    </span>

    <span>
      {undiscountedValue && undiscountedValue > value && (
        <del
          className={cls( 'd-inline-block mr-4 text-muted fs-4 spec__undiscounted-price', {
            [`${className.spec}-undiscounted`]: className.spec,
          })}
        >
          <DollarsAndCents
            amountInCents={undiscountedValue}
            className={{ cents: `${styles.cents} position-relative fs-3` }}
          />
        </del>
      )}

      <span
        className={cls( 'd-inline-block', {
          [`${className.spec}-value`]: className.spec,
        })}
      >
        {typeof value === 'number' ? (
          <DollarsAndCents
            amountInCents={value}
            className={{ cents: `${styles.cents} position-relative fs-3` }}
          />
        ) : (
          value || '--'
        )}
      </span>
    </span>
  </div>
);

export default CartLineItem;
