import React, { Component } from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';

import * as cartDataActions from 'src/explore/data/cart/actions';
import api from 'src/explore/services/api';
import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';
import { LazyImgix } from 'src/explore/elements/LazyImgix';
import SvgIcon from 'src/explore/elements/SvgIcon';

import rewardImg from 'images/cash_hand-2x.png';

import styles from './index.module.sass';

export class PromoRedemption extends Component {
  handleRemove = () => {
    const { fetchCart } = this.props;

    api
      .delete( '/promo_redemption' )
      .then(() => {
        fetchCart();
      })
      .catch(( response ) => {
        // TODO: errors
      });
  };

  render() {
    const { redemption, onEditRedemption } = this.props;

    if ( !redemption ) return null;

    return (
      <div className="v2-cart-shop spec__promo-redemption">
        <div className={cls( 'v2-cart-item', { disabled: !redemption.applicable })}>
          <div className="v2-cart-item__image mr-2">
            <div
              className="v2-cart-item__edit-link"
              role="link"
              tabIndex="0"
              onClick={onEditRedemption}
            >
              <LazyImgix
                src={rewardImg}
                alt="Rewards Points"
                className="img-responsive"
                imgixParams={{ ar: '1:1' }}
                sizes="80px"
              />
              <div className="link text-center">edit</div>
            </div>
          </div>

          <div className="flex-grow-1 mw-100 overflow-hidden">
            <div className="v2-cart-item__product-name-container">
              <div className="v2-cart-item__product-name">
                {`${redemption.price_in_points} Rewards Points`}
              </div>
              <div
                className="v2-cart-item__remove"
                role="link"
                tabIndex="0"
                onClick={this.handleRemove}
              >
                <SvgIcon icon="icon-close-circle" />
              </div>
            </div>

            <div className="v2-cart-item__quantity-price-container">
              <div className="v2-cart-item__quantity" />
              <div className="v2-cart-item__price">
                <span className="v2-cart-item__price-value">
                  <DollarsAndCents
                    amountInCents={-redemption.discount_in_cents}
                    className={{ cents: `${styles.super} position-relative fs-3` }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchCart: () => cartDataActions.fetch(),
};

export default connect( null, mapDispatchToProps )( PromoRedemption );
