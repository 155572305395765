import React from 'react';

import { IAccount } from 'src/explore/types/shoppe';
import ButtonWithIcon from 'src/explore/elements/bs4/ButtonWithIcon';
import SvgIcon from 'src/explore/elements/SvgIcon';

import NumberShortener from 'src/explore/services/NumberShortener';

interface Props {
  account: IAccount;
  isWhitelabel: boolean;
  rewardsPointsEnabled: boolean;
  promoRedemption: any;
  showClose: boolean;
  onBackClicked: () => void;
  onRewardsClick: () => void;
}

const Header: React.FC<Props> = ({
  account,
  isWhitelabel = false,
  rewardsPointsEnabled,
  promoRedemption,
  showClose,
  onBackClicked,
  onRewardsClick,
}) => {
  let pointsBalance = account?.reward_points ?? 0;

  if ( promoRedemption?.applicable ) {
    pointsBalance -= promoRedemption.price_in_points;
  }

  pointsBalance = NumberShortener.shorten( pointsBalance, { min: 100000 });

  const handleKeyDown = ( event: React.KeyboardEvent<HTMLElement> ) => {
    if ( event.key === 'Enter' || event.key === ' ' ) {
      onBackClicked();
    }
  };

  return (
    <header className="v2-cart__head d-flex flex-row flex-nowrap align-items-center">
      {showClose && (
        <span
          role="button"
          className="v2-cart__close-link col-auto px-0 spec__cart__close-btn"
          aria-label="Close"
          onClick={onBackClicked}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <SvgIcon icon="icon-close-arrow-right" svgClasses="d-flex" />
        </span>
      )}

      <h1 className="v2-cart__title col m-0">
        <span className="d-none d-sm-inline">Your </span>
        Cart
      </h1>

      <div className="col-auto px-0">
        {rewardsPointsEnabled && account && (
          <ButtonWithIcon
            className="btn btn-sm btn-outline-secondary spec__rewards-trigger"
            icon="icon-plus"
            onClick={onRewardsClick}
          >
            {`${pointsBalance} Points `}
          </ButtonWithIcon>
        )}

        {rewardsPointsEnabled && !account && !isWhitelabel && (
          <a
            href="/rewards?ref=rewards_cart_header"
            className="btn btn-sm btn-outline-secondary spec__v2-cart__rewards-header"
          >
            Earn Rewards
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
