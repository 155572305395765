import React from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';

import styles from './index.module.sass';

const mapStateToProps = ( state ) => ({
  cart: state.data.cart.data,
});

export const RewardsCoulda = ({ cart, classNames = {}}) => (
  <div
    className={cls( classNames.container, styles.coulda, 'fs-4 text-center spec__rewards-coulda' )}
  >
    {'You will earn up to '}
    <a href="/rewards?ref=rewards_cart_coulda" className={cls( classNames.link, styles.link )}>
      <strong>{cart?.total_in_points}</strong>
      {' Rewards Points'}
    </a>

    {' with this order'}
  </div>
);

export default connect( mapStateToProps )( RewardsCoulda );
