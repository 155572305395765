const redirect = ( to, skipTurbolinks = false ) => new Promise(( resolve ) => setTimeout(() => {
  if ( !skipTurbolinks ) {
    Turbolinks.visit( to );
  } else {
    window.location = to;
  }
  resolve();
}));

export default {
  redirect,
};
