import React, { Component } from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';

import SvgIcon from 'src/explore/elements/SvgIcon';
import * as dataActionCreators from 'src/explore/data/cart/promo_codes/actions';
import * as giftCardCodesActionCreators from 'src/explore/data/cart/gift_card_codes/actions';
import { withShopConfig } from 'src/explore/hocs/withShopConfig';

import DiscountCode from './elements/DiscountCode';
import Error from './elements/Error';
import Form from './elements/Form';
import AuthRequiredMessage from './elements/AuthRequiredMessage';
import styles from './index.module.sass';
import * as viewActionCreators from './actions';

export class DiscountCodes extends Component {
  constructor( props ) {
    super( props );

    const { open = false } = props;

    this.state = {
      open,
    };

    this.toggle = this.toggle.bind( this );
  }

  getSectionTitle( isGiftcardsEnabled, isWhitelabel ) {
    return isWhitelabel && !isGiftcardsEnabled ? 'Promo Code' : 'Promo Code or Gift Card';
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const {
      addPromoCode,
      appliedGiftCards,
      clearErrors,
      egifting = false,
      navyButtons = false,
      error,
      helpUrl,
      isWhitelabel,
      promoCode,
      shopConfig,
      showToggle = true,
      isLoggedIn = false,
    } = this.props;

    const forceOpen = !showToggle && !( promoCode.value || promoCode.value === 0 );
    const open = forceOpen || this.state.open;

    const handleSubmitCode = ( params ) => {
      addPromoCode( params, egifting )
        .then(() => {
          this.setState({
            open: false,
          });
        })
        .catch(() => {
          // error
        });
    };

    return (
      <div
        className="v2-cart__extra-container v2-cart__discount-codes spec__discount-codes"
        aria-expanded={open}
      >
        {showToggle && !( shopConfig?.ui?.hide_discount_section == true ) && (
          <div
            className={`${styles.toggle} d-flex text-secondary`}
            role="button"
            aria-pressed={open}
          >
            <div
              className={`v2-cart__extra-title font-weight-bold spec__promo-code-trigger ${
                open && 'v2-cart__extra-title--open'
              }`}
              onClick={this.toggle}
            >
              <SvgIcon icon="icon-plus" baseline />
              {` ${this.getSectionTitle( shopConfig?.isGiftcardsEnabled, isWhitelabel )}`}
            </div>
          </div>
        )}

        {error && <Error message={error} helpUrl={helpUrl} />}

        <div
          className={cls( `${styles.collapse} v2-cart__extra-content overflow-hidden`, {
            [styles.expand]: open,
          })}
        >
          {isLoggedIn ? (
            <Form
              className="mt-3"
              navyButtons={navyButtons}
              onSubmit={handleSubmitCode}
              onChange={clearErrors}
            />
          ) : (
            <AuthRequiredMessage isGiftCardsEnabled={shopConfig?.isGiftcardsEnabled} />
          )}
        </div>
        {/* A promo may discount $0 in some weird cases */}
        {( promoCode.value || promoCode.value === 0 ) && (
          <DiscountCode
            appliedAmountInCents={promoCode.value}
            code={promoCode.code}
            description="Promo Code"
            onDelete={() => this.props.deletePromoCode( egifting )}
          />
        )}
        {appliedGiftCards.map(( card ) => (
          <DiscountCode
            key={card.code}
            appliedAmountInCents={card.applied_amount_in_cents}
            code={card.code}
            description="Gift Card"
            onDelete={() => this.props.deleteGiftCard( card.code, egifting )}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ( state ) => ({
  promoCode: state.data.cart.data?.discount_codes.promo_code || { value: null, code: null },
  appliedGiftCards: state.data.cart.data?.discount_codes.applied_gift_cards || [],
  isWhitelabel: state.config.isWhitelabel,
  error: state.Cart.PromoCode.error,
  helpUrl: state.Cart.PromoCode.helpUrl,
  isLoggedIn: state.data.account.data,
});

const mapDispatchToProps = {
  addPromoCode: ( params, egifting = false ) => dataActionCreators.create( params, egifting ),
  deletePromoCode: ( egifting = false ) => dataActionCreators.destroy( egifting ),
  deleteGiftCard: ( code, egifting = false ) => giftCardCodesActionCreators.destroy( code, egifting ),
  clearErrors: () => viewActionCreators.clearErrors(),
};

export default withShopConfig( connect( mapStateToProps, mapDispatchToProps )( DiscountCodes ));
