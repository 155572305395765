import React from 'react';

import { ntc } from 'src/explore/services/currency_helper';

interface DollarsAndCentsProps {
  amountInCents: number;
  className?: {
    cents?: string;
  };
}

export const DollarsAndCents = ({ amountInCents, className = {}}: DollarsAndCentsProps ) => (
  <>
    {ntc( amountInCents )
      .split( '.' )
      .map(( text, i ) =>
        i === 0 ? (
          <span key={text}>{text}</span>
        ) : (
          <span className={className.cents} key={text}>
            {`.${text}`}
          </span>
        )
      )}
  </>
);
