import React, { Component } from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';

import * as cartDataActionCreators from 'src/explore/data/cart/actions';
import * as cartItemsActionCreators from 'src/explore/data/cart/cart_items/actions';
import { withRedirector } from 'src/explore/hocs/withRedirector';
import analytics from 'src/explore/services/analytics';
import Eppo from 'src/explore/services/Eppo';
import { isMobileApp } from 'src/explore/services/mobile';
import pickBy from 'lodash-es/pickBy';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import * as authComponentActionCreators from 'src/explore/compositions/Auth/actions';
import Checkout from './elements/Checkout';
import Extras from './elements/Extras';
import Footer from './elements/Footer';
import Header from './elements/Header';
import { KlarnaMessaging } from './elements/KlarnaMessaging';
import Main from './elements/Main';
import { MobileCheckout } from './elements/MobileCheckout';
import { Modal } from './elements/Modal';
import PaymentMethods from './elements/PaymentMethods';
import RewardsCoulda from './elements/RewardsCoulda';
import { RewardsInfo } from './elements/RewardsInfo';
import TopBanner from './elements/TopBanner';

import { PurchaseRewards } from './scenes/PurchaseRewards';
import Rewards from './scenes/Rewards';

import * as cartComponentActionCreators from './actions';

import styles from './index.module.sass';

const SELECTOR_HEADER_CART_ICON = '.js__header-cart-link';
const SELECTOR_ADD_TO_CART = '.js__add-to-cart--trigger';
const SELECTOR_ADD_TO_CART_FORM = '.js__product-form';
const SELECTOR_ADD_TO_CART_FORM_SUBMIT = '.js__add-to-cart';

export class Cart extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      errorMessage: null,
      purchaseRewardsOpen: false,
      rewardsOpen: false,
      successMessage: null,
    };

    // Save URL params
    const searchParams = new URLSearchParams( window.location.search );

    this.recommId = searchParams.get( 'recomm_id' );
    this.searchId = searchParams.get( 'search_id' );
    this.clickRef = searchParams.get( 'ref' );
    this.containerRef = React.createRef();
    this.mobileCheckoutRef = React.createRef();
  }

  componentDidMount() {
    const { fetch, egifting, mode } = this.props;

    if ( typeof $ !== 'undefined' ) {
      $( document ).on( 'click', SELECTOR_HEADER_CART_ICON, this.onCartIconClicked );
      $( document ).on( 'click', SELECTOR_ADD_TO_CART, this.onAddToCartClicked );
      $( document ).on( 'click', SELECTOR_ADD_TO_CART_FORM_SUBMIT, this.onAddToCartFormSubmit );
      $( document ).on( 'cart.toggle', this.onToggle );
    }

    // Fetch initial cart data
    // Little hack, hover mode always works alongside the regular cart
    if ( mode !== 'hover' ) {
      fetch( egifting );
    }

    // reevaluate checkout fix on resize
    window.addEventListener( 'resize', this.handleResize );
    window.addEventListener( 'keydown', this.handleKeyDown );
  }

  componentWillUnmount() {
    if ( typeof $ !== 'undefined' ) {
      $( document ).off( 'click', SELECTOR_HEADER_CART_ICON, this.onCartIconClicked );
      $( document ).off( 'click', SELECTOR_ADD_TO_CART, this.onAddToCartClicked );
      $( document ).off( 'click', SELECTOR_ADD_TO_CART_FORM_SUBMIT, this.onAddToCartFormSubmit );
      $( document ).off( 'cart.toggle', this.onToggle );
    }

    window.removeEventListener( 'resize', this.handleResize );
    window.removeEventListener( 'keydown', this.handleKeyDown );
  }

  handleKeyDown = ( event ) => {
    const { close, visible } = this.props;

    if ( !visible ) {
      return;
    }

    // close on escape
    if ( event.keyCode === 27 ) {
      close();
    }
  };

  handleResize = () => {
    const { containerRef, mobileCheckoutRef } = this;
    const { fixHeader } = this.props;

    fixHeader( containerRef.current?.scrollTop >= mobileCheckoutRef.current?.offsetTop );
  };

  handlePurchaseRewardsClose = () => {
    this.setState({ purchaseRewardsOpen: false });
  };

  handlePurchaseRewardsOpen = () => {
    this.setState({ purchaseRewardsOpen: true });
  };

  handleRewardsClose = () => {
    this.setState({ rewardsOpen: false });
  };

  handleRewardsError = () => {
    const { fetch, egifting } = this.props;

    fetch( egifting );

    this.setState({
      errorMessage: 'Some requirements were not met.',
      rewardsOpen: false,
    });
  };

  handleRewardsOpen = () => {
    this.setState({ rewardsOpen: true });
  };

  handleRewardsSuccess = () => {
    const { fetch, egifting } = this.props;

    fetch( egifting );

    this.setState({
      rewardsOpen: false,
      successMessage: 'Your points have been applied!',
    });
  };

  handleScroll = ( e ) => {
    const { fixHeader } = this.props;

    fixHeader( e.currentTarget.scrollTop >= this.mobileCheckoutRef.current?.offsetTop );
  };

  onCartIconClicked = ( event ) => {
    const { open } = this.props;

    event.preventDefault();

    open();
  };

  onAddToCartClicked = ( event ) => {
    const data = event.target.dataset;

    if ( !data.productId ) return;

    const { addItem, open } = this.props;

    event.preventDefault();

    addItem({
      cart_item: { product_id: data.productId, quantity: data.quantity },
      ref: data.ref,
      recommId: data.recommId,
    });
    open();
  };

  onAddToCartFormSubmit = ( event ) => {
    const { open } = this.props;
    const eventData = event.target.dataset;
    const formData = $( SELECTOR_ADD_TO_CART_FORM ).serialize();

    event.preventDefault();

    if ( eventData.cartItemId ) {
      const { updateItem } = this.props;

      updateItem( eventData.cartItemId, formData );
    } else {
      const { addItem } = this.props;
      const { searchId, recommId, clickRef } = this;

      addItem(
        `${formData}&${new URLSearchParams(
          pickBy({ search_id: searchId, recomm_id: recommId, ref: clickRef })
        ).toString()}`
      );
    }
    open();

    return false;
  };

  onBackClicked = () => {
    const { mode, close } = this.props;

    if ( mode === 'precart' ) {
      close();
    } else {
      window.location = '/';
    }
  };

  onCheckout = ( asGuest = false, isForPickup = false ) => {
    const { account, close, isWhitelabel, redirect, openAuth } = this.props;

    let redirectLocation = '/checkout';

    if ( isWhitelabel ) {
      if ( isForPickup ) {
        redirectLocation = '/pick_up_checkout';
      } else {
        const subdomain = window.location.host.split( '.' )[0];
        redirectLocation = Eppo.isExperimentGroup({
          attributes: {
            email: account?.data?.email,
            id: account?.data?.meta_store?.account_id,
            subdomain,
          },
          experiment: 'new-checkout-on-whitelabel',
          group: 'test',
        })
          ? '/purchase'
          : '/checkout';
        analytics.trackClick({
          'experiment-key': 'new-checkout-on-whitelabel',
          'experiment-value': redirectLocation === '/purchase' ? 'test' : 'control',
          subdomain,
        });
      }
    } else {
      redirectLocation = process.env.RAILS_ENV === 'test' ? '/checkout' : '/purchase'; // TODO: update tests to work with /purchase
    }

    // If mobile app, just go to checkout
    if ( isMobileApp()) {
      close();
      redirect( redirectLocation );
    } else if ( account.data ) {
      // If logged in, just go to checkout
      redirect( redirectLocation, true );
    } else if ( asGuest ) {
      // Else, guest checkout
      redirect( redirectLocation, true );
    } else {
      // Else, show auth modal (includes whitelabels)
      close();
      openAuth( redirectLocation );
    }
  };

  onToggle = () => {
    const { visible, open, close } = this.props;

    if ( visible ) {
      close();
    } else {
      open();
    }
  };

  render() {
    const {
      cart,
      fixed = true,
      isWhitelabel,
      rewardsPointsEnabled,
      mode,
      purchaseTiers,
      visible,
    } = this.props;
    const isPreCartMode = mode === 'precart';
    const isInlineMode = mode === 'inline';
    const isHoverMode = mode === 'hover';
    const isVisible = isInlineMode || !!visible;

    // We want to avoid showing the cart before the initial data is loaded
    if ( cart.pristine || !isVisible ) {
      return null;
    }

    const { errorMessage, purchaseRewardsOpen, rewardsOpen, successMessage } = this.state;
    const cartData = cart.data || {};
    const isForPickUp = !!cartData.is_for_pick_up;

    const { account, close, editable, egifting, fixCheckout, rewards } = this.props;
    const isEmpty = !cart.data || !!cartData.is_empty;
    const hideShopNames = isWhitelabel || isForPickUp;

    const cartValue = isPreCartMode
      ? cart.data?.undiscounted_cart_items_subtotal_in_cents
      : cart.data?.total_in_cents + cart.data?.promotion_discount_in_cents;

    const minAvailableReward = rewards.sort(( a, b ) =>
      a.price_in_points > b.price_in_points ? 1 : 0
    )[0];

    const qualifiesForRewards =
      cartValue >= 5000 && account.data?.reward_points >= minAvailableReward?.price_in_points;

    const showClose = isPreCartMode || isHoverMode;

    return (
      <section
        className={cls(
          styles.cart,
          'd-flex flex-shrink-0 flex-column h-100 pb-md-4 border-left text-dark overflow-auto js-carts spec__cart',
          {
            'position-fixed bg-white': fixed,
            [styles['cart--static']]: !fixed,
            'w-100 bg-light': !fixed,
          }
        )}
        onScroll={this.handleScroll}
        ref={this.containerRef}
      >
        <Header
          account={account.data}
          isWhitelabel={isWhitelabel}
          rewardsPointsEnabled={rewardsPointsEnabled}
          promoRedemption={cartData.cart?.promo_redemption}
          showClose={showClose}
          onBackClicked={close}
          onRewardsClick={this.handleRewardsOpen}
        />

        {!isEmpty && rewardsPointsEnabled && qualifiesForRewards && (
          <div className="spec__rewards-cta-top">
            <RewardsCoulda classNames={{ container: 'pt-4 pb-2', link: 'text-dark' }} />
          </div>
        )}

        <Main
          cart={cartData?.cart}
          editable={editable}
          egifting={egifting}
          fixed={fixed}
          hideShopNames={hideShopNames}
          isEmpty={isEmpty}
          isWhitelabel={isWhitelabel}
          purchaseTiers={purchaseTiers}
          onBackClicked={this.onBackClicked}
          onEditRedemption={this.handleRewardsOpen}
          onViewPurchaseRewards={this.handlePurchaseRewardsOpen}
        />

        {!isEmpty && (
          <Extras
            className="spec__cart-extras"
            egifting={egifting}
            showComment={isForPickUp && !isPreCartMode}
          />
        )}

        {!isEmpty && rewardsPointsEnabled && (
          <div className={cls( styles.border, 'mx-6 mt-4 pt-4 spec__rewards-cta-bottom' )}>
            {qualifiesForRewards ? (
              <RewardsInfo className="px-6" onRewardsClick={this.handleRewardsOpen} />
            ) : (
              <RewardsCoulda
                classNames={{
                  link: cls({ 'text-dark': qualifiesForRewards }),
                }}
              />
            )}
          </div>
        )}

        {!isEmpty && (
          <>
            <Footer mode={mode} />

            {isPreCartMode && (
              <Checkout
                account={account.data}
                isWhitelabel={isWhitelabel}
                isForPickUp={isForPickUp}
                onCheckout={this.onCheckout}
              />
            )}

            <KlarnaMessaging isPreCartMode={isPreCartMode} />

            {isPreCartMode && (
              <div className="hidden-xs">
                <PaymentMethods />
              </div>
            )}
          </>
        )}

        {!isEmpty && isPreCartMode && (
          <MobileCheckout
            affixCheckout={fixCheckout}
            account={account.data}
            cart={cartData?.cart}
            isForPickUp={isForPickUp}
            onCheckout={this.onCheckout}
            ref={this.mobileCheckoutRef}
          />
        )}

        {purchaseRewardsOpen && (
          <Modal onClose={this.handlePurchaseRewardsClose}>
            <PurchaseRewards tiers={purchaseTiers} onClose={this.handlePurchaseRewardsClose} />
          </Modal>
        )}

        {rewardsOpen && (
          <Modal onClose={this.handleRewardsClose}>
            <Rewards
              account={account.data}
              selectedPromoId={cartData.cart?.promo_redemption?.reward_promotion_id}
              onClose={this.handleRewardsClose}
              onSuccess={this.handleRewardsSuccess}
              onError={this.handleRewardsError}
            />
          </Modal>
        )}

        {errorMessage && (
          <TopBanner
            mode="error"
            icon="icon-warn"
            onHide={() => this.setState({ errorMessage: null })}
          >
            {errorMessage}
          </TopBanner>
        )}

        {successMessage && (
          <TopBanner mode="success" onHide={() => this.setState({ successMessage: null })}>
            {successMessage}
          </TopBanner>
        )}
      </section>
    );
  }
}

const mapStateToProps = ( state ) => ({
  account: state.data.account,
  cart: state.data.cart,
  isWhitelabel: state.config.isWhitelabel,
  rewardsPointsEnabled: state.config.rewardsPointsEnabled || !state.config.isWhitelabel,
  fixCheckout: state.Cart.fixCheckout,
  purchaseTiers: state.data.purchaseRewardTiers?.data,
  rewards: state.data.rewardPromos.data || [],
  visible: state.Cart.visible,
});

const mapDispatchToProps = {
  fetch: ( egifting = false ) => cartDataActionCreators.fetch( egifting ),
  open: () => cartComponentActionCreators.open(),
  close: () => cartComponentActionCreators.close(),
  fixHeader: ( isFixed ) => cartComponentActionCreators.fixHeader( isFixed ),
  addItem: ( params ) => cartItemsActionCreators.create( params ),
  updateItem: ( id, params ) => cartItemsActionCreators.update( id, params ),
  openAuth: ( redirectTo ) => authComponentActionCreators.open( redirectTo ),
};

export default withRedirector( connect( mapStateToProps, mapDispatchToProps )( Cart ));
