import React from 'react';

import GiftCards from './compositions/GiftCards';
import Subcarts from './compositions/Subcarts';

interface CartItemsProps {
  className?: {
    image?: string;
    merchant?: string;
    product?: string;
    warning?: string;
    warningIcon?: string;
  };
  editable?: boolean;
  egifting?: boolean;
  hideEditLink?: boolean;
  hideShopNames?: boolean;
  verbose?: boolean;
}

export const CartItems = ({
  className,
  editable,
  egifting,
  hideEditLink,
  hideShopNames,
  verbose,
}: CartItemsProps ) => (
  <>
    <Subcarts
      className={className}
      editable={editable}
      egifting={egifting}
      hideEditLink={hideEditLink}
      hideShopName={hideShopNames}
      verbose={verbose}
    />

    <GiftCards className={className} editable={editable} egifting={egifting} verbose={verbose} />
  </>
);
