import React from 'react';

import { useShopConfig } from 'src/explore/hooks/useShopConfig';

export const withShopConfig = ( WrappedComponent ) => {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  const EnhancedComponent = ( props ) => {
    const config = useShopConfig();

    return <WrappedComponent {...props} shopConfig={config} />;
  };

  EnhancedComponent.displayName = `withShopConfig(${componentName})`;

  return EnhancedComponent;
};
