import React, { useCallback, useRef } from 'react';

import styles from './index.module.sass';

export const Modal = ({ children, onClose }) => {
  const elem = useRef();

  const handleClick = useCallback(
    ( event ) => {
      if ( event.target === elem.current ) {
        onClose();
      }
    },
    [ onClose ]
  );

  return (
    <div className={styles.component} ref={elem} onClick={handleClick}>
      {children}
    </div>
  );
};
