// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

const ToSentence = ({ children }) => {
  if ( !children ) return null;

  const fullArray = [];
  const childrenLength = children.length;

  children.forEach(( elem, index ) => {
    fullArray.push( elem );

    if ( index < childrenLength - 2 ) {
      fullArray.push( ', ' );
    } else if ( index === childrenLength - 2 ) {
      fullArray.push( ' and ' );
    }
  });

  return fullArray;
};

export default ToSentence;
