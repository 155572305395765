import React, { Component } from 'react';

import Redirector from 'src/explore/elements/Redirector';

export const withRedirector = ( WrappedComponent ) => {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  class EnhancedComponent extends Component {
    constructor( props ) {
      super( props );

      this.state = {};

      this.handleRedirect = this.handleRedirect.bind( this );
    }

    handleRedirect( to, skipTurbolinks = false ) {
      this.setState({ to, skipTurbolinks });
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} redirect={this.handleRedirect} />
          <Redirector {...this.state} />
        </>
      );
    }
  }

  EnhancedComponent.displayName = `withRedirector(${componentName})`;

  return EnhancedComponent;
};
