import React from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';

import * as cartActionCreators from 'src/explore/data/cart/actions';
import * as giftCardsActionCreators from 'src/explore/data/cart/gift_cards/actions';
import GiftCard from './elements/GiftCard';

const GiftCards = ({
  className = {},
  giftCards,
  editable,
  egifting = false,
  removeGiftCard,
  verbose,
}) =>
  giftCards.length > 0 ? (
    <div className="v2-cart-shop">
      <div className={cls( 'font-weight-bold text-truncate', className.merchant )}>
        {verbose && 'Order from '}
        Goldbelly
      </div>
      {giftCards.map(( card ) => (
        <GiftCard
          className={className}
          card={card}
          key={card.id}
          editable={editable}
          egifting={egifting}
          onRemove={() => removeGiftCard( card.id )}
        />
      ))}
    </div>
  ) : null;

const mapStateToProps = ( state ) => ({
  giftCards: state.data.cart.data.gift_cards,
});

const mapDispatchToProps = ( dispatch ) => ({
  removeGiftCard: ( id ) => {
    dispatch( giftCardsActionCreators.destroy( id )).then(() => {
      // TODO: is this correct? reloading the cart from a child component?
      if ( /^\/buy\/?/.test( window.location.pathname )) {
        window.location.reload();
      } else {
        dispatch( cartActionCreators.fetch());
      }
    });
  },
});

export default connect( mapStateToProps, mapDispatchToProps )( GiftCards );
