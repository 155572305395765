import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

export const Option = ({ disabled, option, selected, onSelect }) => {
  const handleClick = ( event ) => {
    event.preventDefault();

    if ( !disabled ) onSelect();
  };

  return (
    <div className={cls( 'col-6 mb-1 spec__reward-option', styles.optionWrapper )}>
      <div
        className={cls( 'py-4 rounded', styles.option, {
          [styles.disabled]: disabled,
          [styles.selected]: selected,
        })}
        onClick={handleClick}
        role="button"
        tabIndex="0"
      >
        <div className="fs-9 font-weight-bold">{option.discount_text}</div>
        <div className="mt-2 fs-3">{`${option.price_in_points} points`}</div>
      </div>
    </div>
  );
};

const Options = ({ account, options, selectedId, onSelect }) => (
  <div className="row no-gutters">
    {options.map(( option ) => (
      <Option
        key={option.id}
        option={option}
        selected={option.id === selectedId}
        disabled={option.price_in_points > account.reward_points}
        onSelect={() => onSelect( option.id )}
      />
    ))}
  </div>
);

export default Options;
