import React, { Component } from 'react';

import redirectService from 'src/explore/services/redirect';

class Redirector extends Component {
  constructor( props ) {
    super( props );

    this.redirecting = false;
  }

  render() {
    const { to, skipTurbolinks } = this.props;

    if ( !!to && !this.redirecting ) {
      this.redirecting = true;
      redirectService.redirect( to, skipTurbolinks ).then(() => {
        this.redirecting = false;
      });
    }

    // Empty component
    return null;
  }
}

export default Redirector;
