import React, { Component } from 'react';
import cls from 'classnames';

import SvgIcon from 'src/explore/elements/SvgIcon';

import styles from './index.module.sass';

class TopBanner extends Component {
  state = {
    hidden: false,
  };

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate() {
    this.startTimer();
  }

  handleClick = () => {
    const { onHide } = this.props;

    if ( this.timeoutId ) {
      clearTimeout( this.timeoutId );
    }

    this.setState({ hidden: true });

    if ( onHide ) {
      onHide();
    }
  };

  startTimer = () => {
    const { onHide } = this.props;

    if ( this.timeoutId ) {
      clearTimeout( this.timeoutId );
    }

    this.timeoutId = setTimeout(() => {
      this.setState({ hidden: true }, () => {
        if ( onHide ) {
          setTimeout( onHide, 400 );
        }
      });
    }, 3000 );
  };

  render() {
    const { hidden } = this.state;
    const { children, icon, mode = '' } = this.props;

    return (
      <div
        className={cls( 'd-flex flex-row py-6 text-center', styles.component, styles[mode], {
          [styles.slideOut]: hidden,
        })}
        role="button"
        onClick={this.handleClick}
      >
        {icon && (
          <div className={styles.icon}>
            <SvgIcon icon={icon} />
          </div>
        )}

        <div className={`col text-truncate ${styles.text}`}>{children}</div>
      </div>
    );
  }
}

export default TopBanner;
