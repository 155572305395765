import React, { Component } from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';

import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

import ButtonWithIcon from 'src/explore/elements/bs4/ButtonWithIcon';
import { LoadingButton } from 'src/shoppe/elements/LoadingButton';
import Options from './elements/Options';

import styles from './index.module.sass';

export class Rewards extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      loading: false,
      selectedPromoId:
        props.promos &&
        props.promos.length > 0 &&
        !props.selectedPromoId &&
        props.account.reward_points >= props.promos[0].price_in_points
          ? props.promos[0].id
          : props.selectedPromoId,
    };
  }

  handlePromoSelect = ( selectedPromoId ) => {
    this.setState({ selectedPromoId });
  };

  handleRedeem = () => {
    const { selectedPromoId } = this.state;
    const { selectedPromoId: previousSelectedPromoId, onClose } = this.props;

    if ( previousSelectedPromoId === selectedPromoId ) {
      onClose();
      return;
    }

    const { promos, onError, onSuccess } = this.props;

    this.setState({ loading: true });

    const promo = promos.find(( _promo ) => _promo.id === selectedPromoId );

    api
      .post( 'promo_redemption', {
        promo_redemption: {
          promotion_id: promo.promotion_id,
        },
      })
      .then(() => {
        this.setState({ loading: false });
        onSuccess();
      })
      .catch(( error ) => {
        this.setState({ loading: false });
        onError( new ApiError( error.response ));
      });
  };

  render() {
    const { account, promos, onClose } = this.props;
    const { loading, selectedPromoId } = this.state;

    return (
      <div className={cls( 'pt-5 pb-8 px-6 text-center', styles.component )}>
        <div className="d-flex flex-row no-gutters justify-content-end">
          <div className="col-4">
            <ButtonWithIcon
              className="btn btn-sm btn-outline-secondary btn-block"
              svgClasses="rotate"
              icon="icon-plus"
              onClick={onClose}
            >
              Close
            </ButtonWithIcon>
          </div>
        </div>
        <div className="mt-6 fs-8 font-weight-bold">
          You Have{' '}
          <a href="/rewards?ref=rewards_cart_display" className="text-secondary">
            {account.reward_points}
          </a>{' '}
          Points.
        </div>
        <div className="mt-4 fs-4">Instantly redeem your points for the rewards below</div>
        <div className="mt-6">
          <Options
            account={account}
            options={promos}
            selectedId={selectedPromoId}
            onSelect={this.handlePromoSelect}
          />
        </div>
        <div className="mt-5 fs-3">
          Orders must be a minimum of $50 and you can only use ONE reward at a time. In addition,
          reward points cannot be combined with additional discounts or promo codes.{' '}
          <a
            href="https://help.goldbelly.com/hc/en-us/articles/115003767773-How-Does-the-Belly-Rewards-Program-Work-"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Terms & Conditions.
          </a>
        </div>
        <div className="mt-6 d-flex flex-row align-items-center">
          <div className="col-6">
            <a href="/rewards?ref=rewards_learn">Learn More</a>
          </div>
          <div className="col-6">
            <LoadingButton
              type="button"
              className="btn btn-secondary btn-block"
              disabled={!account.reward_points}
              loading={loading}
              onClick={this.handleRedeem}
            >
              Redeem
            </LoadingButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ( state ) => ({
  promos: state.data.rewardPromos.data,
});

export default connect( mapStateToProps )( Rewards );
