import React from 'react';
import cls from 'classnames';

import { ntc } from 'src/explore/services/currency_helper';

import styles from './index.module.sass';

export const PurchaseRewards = ({ className, tiers, onSeeMore }) => {
  if ( !tiers ) return null;

  return (
    <div
      className={cls(
        'px-8 py-4 text-center text-white spec__purchase-rewards-info',
        styles.component,
        className
      )}
    >
      <div className={cls( 'font-weight-bold fs-6', styles.title )}>
        {`SPEND ${ntc( tiers[0].min_total_in_cents, { verbose: false })}+`}
        <br />& GET A FREE GIFT CARD!
      </div>

      <div className="fs-3 mt-1">
        Order by 10/29 and schedule delivery by 11/5.{' '}
        <button
          type="button"
          className={cls( 'btn btn-link fs-3 p-0 text-white', styles.seeMore )}
          onClick={onSeeMore}
        >
          See More
        </button>
      </div>
    </div>
  );
};
