import React from 'react';
import cls from 'classnames';

import { CartItems } from 'src/explore/compositions/CartItems';
import SvgIcon from 'src/explore/elements/SvgIcon';

import Empty from '../Empty';
import ErrorBox from '../ErrorBox';
import PromoRedemption from '../PromoRedemption';
import { PurchaseRewards } from './elements/PurchaseRewards';

import styles from './index.module.sass';

const Main = ({
  cart,
  editable,
  egifting,
  fixed,
  hideShopNames,
  isEmpty,
  purchaseTiers,
  onBackClicked,
  onEditRedemption,
  onViewPurchaseRewards,
}) => {
  const errors = cart?.promo_redemption?.errors;

  return (
    <main
      className={cls( styles.container, 'v2-cart__body spec__cart-body', {
        'v2-cart__body--empty': isEmpty,
        'flex-grow-0': !fixed,
      })}
    >
      {isEmpty ? (
        <Empty onBackClicked={onBackClicked} />
      ) : (
        <>
          {errors && errors.length > 0 && (
            <ErrorBox className="mb-8 fs-4 spec__cart-errors">
              <ul>
                {errors.map(( error ) => (
                  <li className="d-flex flex-row no-gutters">
                    <SvgIcon icon="icon-warn" svgClasses="flex-grow-0 mr-2" />
                    <div className="col">{error}</div>
                  </li>
                ))}
              </ul>
            </ErrorBox>
          )}

          <div className="spec__shops mb-8">
            <CartItems
              className={{ merchant: 'fs-5', product: 'font-weight-bold' }}
              editable={editable}
              egifting={egifting}
              hideShopNames={hideShopNames}
              verbose
            />

            <PromoRedemption
              redemption={cart?.promo_redemption}
              onEditRedemption={onEditRedemption}
            />

            {purchaseTiers && (
              <PurchaseRewards
                className="mt-4"
                tiers={purchaseTiers}
                onSeeMore={onViewPurchaseRewards}
              />
            )}
          </div>
        </>
      )}
    </main>
  );
};

export default Main;
